import React from 'react';
import Router from "./Router";

if (typeof SharedArrayBuffer === 'undefined') {
  window.SharedArrayBuffer = ArrayBuffer;
}
function App() {
  return (
    <Router/>
  );
}

export default App;